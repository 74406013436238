<template>
    <CategoryTable ref="catTable" :items="items" :fetchItems="fetchItems" :itemsAtEnd="itemsAtEnd" :path="validPath"
        :tableColumns="tableColumns" :filters="filters" :sorting="sorting" @filterUpdate="filterUpdate"
        @searchUpdate="searchUpdate" @sortUpdate="sortUpdate">
   
        <router-link :to="{ name: 'AddThing' }" >
            <AddButton title="Add Thing" v-show="!isAuthorized">
                <font-awesome-icon icon="fa-solid fa-plus" />
            </AddButton>
        </router-link>
    </CategoryTable>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from "vue-router";
import CategoryTable from '@/components/widgets/categoryTable/CategoryTable.vue';
import AddButton from '../components/inputs/AddButton.vue';
import capitalize from '../utils/Capitalize';
import match from '@/utils/Match';
import CapitalizeWords from "@/utils/CapitalizeWords"

const isAuthorized = computed(() => store.getters.isUserAuthorizedToEdit);

const listEmptyOrFirstFalse = list =>
    list.length === 0 || (list.length === 1 && !list[0])

const store = useStore()
store.dispatch("fetchProfileFilters")
const router = useRouter()
const route = useRoute()

const queryParameters = ref({ before: "", after: "", searchFilter: "", categoryId: "" })
const items = ref([])
const itemsAtEnd = ref(false)
const catTable = ref(null)

const fetchItems = async (numRequested, reset) => {
    reset = reset ?? false

    // Set the query parameters that need to be customized for the next query
    if (reset) queryParameters.value = { ...queryParameters.value, first: numRequested, after: "" }
    else queryParameters.value = { ...queryParameters.value, first: numRequested }

    // Make the query to the server
    const { things, pageInfo } = await store.dispatch("getBasicThings", queryParameters.value)
    const newItems = things.map(thing => ({
        id: thing.id,
        title: CapitalizeWords(thing.name),
        subtitle: CapitalizeWords(thing.owner?.basic?.fullName) ?? "Unknown",
        image: thing.medialibrary?.images?.[0]?.image ?? "https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/production_placeholder.png",
        to: { name: 'Thing', params: { id: thing.id } },
    }))

    // Set the after value for next query
    queryParameters.value = { ...queryParameters.value, after: pageInfo.endCursor ?? "" }
    itemsAtEnd.value = !pageInfo.hasNextPage

    // Insert the new items
    if (reset) items.value = [...newItems]
    else items.value = [...items.value, ...newItems]
}

const sortUpdate = (sortBy) => {
    queryParameters.value = { ...queryParameters.value, sortBy }
    // Call our own fetch function through the main component so that it can manage what is allowed
    // to run at the same time and so that it knows when something is still loading. DO NOT call
    // your own fetch function without going through the display component.
    catTable.value.runFetchItems(24, true)
}

const filterUpdate = (selectedFilters, filtersOnInclude) => {
    // Set the regular keys
    const entries = Object.entries(selectedFilters)       // Get object as entries
    const filterKeys = entries
        .filter(([key,]) => !key.startsWith("custom_")) // Remove all the custom keys, they need special handling
        .map(([key, val]) => [key, val.join(",")])        // Combine multiple values with commas

    // Convert the keys from the value they were generated with in formatCustomFilter and turn them
    // into the JSON format the server wants them in
    let customKeys;
    try {
        customKeys = entries
            .filter(([key,]) => key.startsWith("custom_"))   // Filter out any regular filter keys, we only want custom
            .filter(([,val]) => !listEmptyOrFirstFalse(val)) // Remove any unset values, sometimes they include a falsy
                                                             // first item even if they should be unset
            .map(([key, val]) => [key.split("_"), val])      // Split the key into prefix, type and id like generated below
            .map(([[, type, id], value]) => match(type, {    // Turn into what the server wants for each type
                "multiOpt": () => ({ id, value }),
                "singleOpt": () => ({ id, value: value[0] }),
                "int": () => ({ id, value: value[0] }),
                "bool": () => ({ id, value: value[0] }),
            }))
    } catch (error) {
        console.error(error)
        customKeys = []
    }
    // Leave them as an empty string if no custom keys were set
    let customProperty = ""
    if (customKeys.length > 0)
        customProperty = JSON.stringify(customKeys)
    console.log("customProperty", customProperty)

    // TODO: Add include/exclude functionality when it is available in the server
    filtersOnInclude;

    queryParameters.value = { ...queryParameters.value, ...Object.fromEntries(filterKeys), customProperty }

    catTable.value.runFetchItems(24, true)
}

const searchUpdate = newSearchString => {
    queryParameters.value.searchFilter = newSearchString
    catTable.value.runFetchItems(24, true)
}

// Make sure the path is only displayed when it has been verified, otherwise we would show whatever
// URL the user goes to before they're redirected. This will display the base path until the full
// path has been verified and at that point the full path is displayed.
const pathIsValid = ref(false)
const path = computed(() => (route.params.thingPath || []).map(
    (item, i, arr) => ({
        label: capitalize(item),
        goto: () => router.push({
            name: "Things",
            params: { thingPath: arr.slice(0, i + 1) },
        })
    })
))
const basePath = [{ label: "Things", goto: () => router.push({ name: "Things" }) }]
const validPath = computed(() => pathIsValid.value ? [...basePath, ...path.value] : basePath)

const validateCurrentPath = async newPath => {
    if (newPath.length === 0) {
        // Make sure there isn't any categoryId filter left in the params
        if (queryParameters.value.categoryId) {
            queryParameters.value.categoryId = ""
            catTable.value.runFetchItems(24, true)
        }

        // Don't do any filtering when the user requests all things
        return
    }

    pathIsValid.value = false
    const { valid, categoryId } =
        await store.dispatch("isValidCategoryPath", newPath.map(item => item.label.toLowerCase()))

    if (valid) {
        queryParameters.value.categoryId = categoryId
        pathIsValid.value = true
        catTable.value.runFetchItems(24, true)
    }
    else router.push({ name: "error-404" })
}

watch(path, validateCurrentPath)
validateCurrentPath(path.value)

const tableColumns = [
    { label: "Name", key: "title", size: 6 },
    { label: "Owner", key: "subtitle", size: 6 },
]

const sorting = [
    {
        label: "Name",
        key: "title",
        outKey: "name",
        ordering: "Alphabetical"
    },
    {
        label: "Owner",
        key: "owner",
        outKey: "owner",
        ordering: "Alphabetical"
    },
]

watch(() => queryParameters.value.categoryId, (categoryId, oldCategoryId) => {
    if (!categoryId && categoryId !== oldCategoryId) return

    store.dispatch("fetchCategoryCustomProperties", categoryId)
})

const formatCustomFilter = properties => {
    const defaultPropOpts = {
        label: properties.name,
        includeExclude: false,
        category: "Custom Filters",
        options: [],
    }
    // Here we generate the custom filters and store their types and IDs in they key so that we can
    // parse those out later in filterUpdate.
    return match(properties.propertyType, {
        "OPTION_M": () => ({
            ...defaultPropOpts,
            key: `custom_multiOpt_${properties.id}`,
            type: "Dropdown",
            options: properties.options,
        }),
        "OPTION_S": () => ({
            ...defaultPropOpts,
            key: `custom_singleOpt_${properties.id}`,
            type: "Dropdown",
            options: properties.options,
        }),
        "INT": () => ({
            ...defaultPropOpts,
            key: `custom_int_${properties.id}`,
            type: "Int",
        }),
        "BOOLEAN": () => ({
            ...defaultPropOpts,
            key: `custom_bool_${properties.id}`,
            type: "Bool",
        }),
        "_": type => { // Make sure we don't crash and go into an infinite loop if we get something unexpected.
            console.warn(`Unexpected type "${type}", I do not know how to handle this type.`)
            return {
                ...defaultPropOpts,
                key: "",
                type: "Unknown",
            }
        },
    })
}

const filters = computed(() => [
    ...store.getters
        .getCategoryCustomProperties(queryParameters.value.categoryId)
        .map(formatCustomFilter),
    {
        label: "Category",
        key: "category",
        includeExclude: true,
        category: "General",
        type: "Dropdown",
        options: []
    },
    {
        label: "Author/Brand",
        key: "author_brand",
        includeExclude: true,
        category: "General",
        type: "Dropdown",
        options: []
    },
    {
        label: "Tags",
        key: "tags",
        includeExclude: false,
        category: "General",
        type: "Textbox",
        options: []
    },
    {
        label: "Colour",
        key: "colour",
        includeExclude: true,
        category: "Appearance",
        type: "Dropdown",
        options: []
    },
    {
        label: "Appearance",
        key: "appearance",
        includeExclude: true,
        category: "Appearance",
        type: "Dropdown",
        options: []
    },
    {
        label: "Exterior Appearance",
        key: "exteriorAppearance",
        includeExclude: true,
        category: "Appearance",
        type: "Dropdown",
        options: []
    },
    {
        label: "Size",
        key: "size",
        includeExclude: true,
        category: "Practical",
        type: "Dropdown",
        options: []
    },
    {
        label: "Material",
        key: "material",
        includeExclude: true,
        category: "Practical",
        type: "Dropdown",
        options: []
    },
    {
        label: "Functionality",
        key: "functionality",
        includeExclude: true,
        category: "Practical",
        type: "Dropdown",
        options: []
    },
    {
        label: "Bundle",
        key: "bundle",
        includeExclude: true,
        category: "Practical",
        type: "Dropdown",
        options: []
    },
    {
        label: "Real/Fake",
        key: "real_fake",
        includeExclude: true,
        category: "Practical",
        type: "Dropdown",
        options: []
    },
    {
        label: "Flame retardant",
        key: "flameRetardant",
        includeExclude: false,
        category: "Practical",
        type: "Dropdown",
        options: []
    },
    {
        label: "Country",
        key: "country",
        includeExclude: true,
        category: "Location",
        type: "Dropdown",
        options: []
    },
    {
        label: "City",
        key: "city",
        includeExclude: true,
        category: "Location",
        type: "Dropdown",
        options: []
    },
    {
        label: "Place",
        key: "place",
        includeExclude: true,
        category: "Location",
        type: "Dropdown",
        options: []
    },
    {
        label: "Storage",
        key: "storage",
        includeExclude: true,
        category: "Location",
        type: "Dropdown",
        options: []
    },
])


</script>
